import request from '@/utils/request'

// 入库任务分页查询
export function pageTrayTask(params) {
  return request({
    url: `inbound/pageTrayTask`,
    method: 'get',
    params
  })
}

// 收货任务分页查询
export function pageCollectTask(params) {
  return request({
    url: `inbound/pageCollectTask`,
    method: 'get',
    params
  })
}

// 上架任务分页查询
export function pageShelfTask(params) {
  return request({
    url: `inbound/pageShelfTask`,
    method: 'get',
    params
  })
}
// 收货单明细查询
export function findCollectDetail(data) {
  return request({
    url: `inbound/findCollectDetail`,
    method: 'post',
    data
  })
}
// 上架单明细查询
export function findShelfDetail(data) {
  return request({
    url: `inbound/findShelfDetail`,
    method: 'post',
    data
  })
}

// 处置申请导入
export function disposeApplyImport(data) {
  return request({
    url: `handle/disposeApplyImport`,
    method: 'post',
    data
  })
}
// 处置申请提交
export function disposeApplyCommit(data) {
  return request({
    url: `handle/disposeApplyCommit`,
    method: 'post',
    data
  })
}
// 处置申请删除/批量删除
export function deleteApplyBatch(data) {
  return request({
    url: `handle/deleteApplyBatch`,
    method: 'post',
    data
  })
}

// 处置申请分页查询
export function pageDisposeApply(data) {
  return request({
    url: `handle/pageDisposeApply`,
    method: 'post',
    data
  })
}

// 处置申请详情查询
export function getDisposeApplyDetail(params) {
  return request({
    url: `handle/getDisposeApplyDetail`,
    method: 'get',
    params
  })
}
// 库存处置分页查询
export function pageDispose(data) {
  return request({
    url: `handle/pageDispose`,
    method: 'post',
    data
  })
}
// 库存处置详情
export function getDisposeDetail(params) {
  return request({
    url: `handle/getDisposeDetail`,
    method: 'get',
    params
  })
}
// 库存处置导入
export function disposeImport(data) {
  return request({
    url: `handle/disposeImport`,
    method: 'post',
    data
  })
}
// 库存处置提交
export function disposeCommit(data) {
  return request({
    url: `handle/disposeCommit`,
    method: 'post',
    data
  })
}
// 库存处置删除/批量删除
export function deleteDisposeBatch(data) {
  return request({
    url: `handle/deleteDisposeBatch`,
    method: 'post',
    data
  })
}

// 入库单仓库查询 // VUE_APP_IMS_SC_API
export function isWarehouseList(params) {
  return request({
    url: `http://10.2.3.120:5002/sc-api/api/warehouse/queryAllWarehouseList`,
    method: 'get',
    params
  })
}

